.mainContainer {
  background-image: url("../../../../public/assets/images/homePage/bg4.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  flex-direction: column;
  padding-top: 180px;
  @media screen and (max-width: 320px) {
    padding-top: 100px;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    padding-top: 100px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 120px;

  }
  @media (min-width: 767px) and (max-width: 991px) {
    padding-top: 130px;

  }
  @media (min-width: 991px) and (max-width: 1199px) {
    padding-top: 140px;

  }
}

.title {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-size: 36px !important;
  line-height: 54px !important;
  color: #242424 !important;
  text-shadow: 0px 0px 4px #ffffff !important;
  padding-bottom: 24px;
  width: 400px;
  @media screen and (max-width: 320px) {
    font-size: 18px !important;
    line-height: 26px !important;
    padding-bottom: unset;
    width: 200px;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 18px !important;
    line-height: 26px !important;
    padding-bottom: unset;
    width: 200px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 28px !important;
    line-height: 30px !important;
    padding-bottom: 10px;
    width: 350px;

  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 24px !important;
    line-height: 28px !important;
    padding-bottom: 10px;
    width: 350px;

  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 30px !important;
    line-height: 35px !important;
    padding-bottom: 10px;
    width: 350px;

  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.imgContainer1 {
  align-self: flex-start;
  display: flex;
  align-items: center;
  position: relative;
}
.imgContainer2 {
  align-self: flex-end;
  display: flex;
  align-items: center;
  position: relative;
}

.img1 {
  width: 65%;
  margin: 0 auto;
}

.img3,
.img4 {
  width: 288px;
  height: auto;
  @media screen and (max-width: 320px) {
    width: 60px;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 420px) {
    width: 75px;
    height: auto;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    width: 100px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 160px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 180px;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 220px;
    height: auto;
  }
}

.img5 {
  width: 67px;
  height: auto;
  position: absolute;
  left: 270px;
  top: 65px;

  @media screen and (max-width: 320px) {
    width: 27px;
    height: auto;
    left: 60px;
    top: 4px;
  }
  @media (min-width: 320px) and (max-width: 420px) {
    width: 33px;
    height: auto;
    left: 70px;
    top: 10px;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    width: 39px;
    height: auto;
    left: 100px;
    top: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 47px;
    height: auto;
    left: 150px;
    top: 35px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 57px;
    height: auto;
    left: 170px;
    top: 40px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 67px;
    height: auto;
    left: 210px;
    top: 50px;
  }
}
.img6 {
  width: 67px;
  height: auto;
  position: absolute;
  right: 270px;
  top: 25px;

  @media screen and (max-width: 320px) {
    width: 25px;
    height: auto;
    right: 60px;
    top: 0px;
  }
  @media (min-width: 320px) and (max-width: 420px) {
    width: 30px;
    height: auto;
    right: 70px;
    top: 0px;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    width: 35px;
    height: auto;
    right: 90px;
    top: 0px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 47px;
    height: auto;
    right: 150px;
    top: 5px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 57px;
    height: auto;
    right: 170px;
    top: 5px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 67px;
    height: auto;
    right: 210px;
    top: 5px;
  }
}

.card {
  width: 65%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 24px;
  border-radius: 32px;
  margin-top: 20px;
}

.description {
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: "Poppins400" !important;
  color: rgba(36, 36, 36, 1) !important;
  text-align: start;
  margin-bottom: 20px;
}
