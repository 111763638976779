.slider {
  background-image: url("../../../../public/assets/images/homePage/bg2.png");
  background-size: cover;
  height: max-content;
  width: 100%;
  @media screen and (max-width: 576px) {
    min-height: max-content;
    padding: 30px 0;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-height: max-content;
    padding: 30px 0;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    min-height: max-content;
    padding: 30px 0;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    min-height: max-content;
    padding: 30px 0;
  }
}

.sliderContainer {
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  position: relative;
  width: 100%;
}
