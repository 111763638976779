.darkContainer {
  background-image: url("../../../../../public/assets/images/homePage/bg2.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  padding-top: 5em;
  display: flex;
  flex-direction: column;
  padding: 100px;
  height: 100%;
  padding-bottom: unset;
  z-index: -6;
  @media screen and (max-width: 576px) {
    padding: 20px;
    padding-bottom: 0;
    height: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 30px;
    padding-bottom: 0;
    height: 100%;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    padding: 50px;
    padding-bottom: 0;
    height: 100%;
    min-height: unset;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    padding: 50px;
    padding-bottom: 0;
    height: 100%;
    min-height: unset;
  }
}
.lightContainer {
  background-image: url("../../../../../public/assets/images/homePage/bg1.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  padding-top: 5em;
  display: flex;
  flex-direction: column;
  padding: 100px;
  height: 100%;
  padding-bottom: unset;
  background-position: top;
  @media screen and (max-width: 576px) {
    padding: 20px;
    padding-bottom: 0;
    height: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 30px;
    padding-bottom: 0;
    height: 100%;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    padding: 50px;
    padding-bottom: 0;
    height: 100%;
    min-height: unset;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    padding: 50px;
    padding-bottom: 0;
    height: 100%;
    min-height: unset;
  }
}
.tg1 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 64px !important;
  line-height: 96px !important;
  color: #03a197 !important;
  text-align: start;
  &:hover,
  &:active,
  &:focus {
    color: #f2e024 !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 35px !important;
    line-height: 35px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 40px !important;
    line-height: 45px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 45px !important;
    line-height: 50px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 50px !important;
    line-height: 55px !important;
  }
}

.dtg2 {
  font-family: "Poppins900" !important;
  color: #ffffff !important;
  font-style: normal !important;
  font-size: 48px !important;
  font-weight: 900 !important;
  line-height: 72px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  width: unset !important;
  &:hover,
  &:active,
  &:focus {
    color: rgba(199, 199, 199, 1) !important;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
  }
  @media screen and (max-width: 576px) {
    font-size: 25px !important;
    line-height: 42px !important;
    width: 250px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px !important;
    line-height: 45px !important;
    width: 280px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 28px !important;
    line-height: 40px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 35px !important;
    line-height: 62px !important;
  }
  @media (min-width: 1199px) and (max-width: 1400px) {
    font-size: 40px !important;
    line-height: 62px !important;
  }
}
.ltg2 {
  font-family: "Poppins900" !important;
  color: rgba(36, 36, 36, 1) !important;
  font-style: normal !important;
  font-size: 48px !important;
  font-weight: 900 !important;
  line-height: 72px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  margin-bottom: 20px;
  width: unset !important;

  &:hover,
  &:active,
  &:focus {
    color: rgba(199, 199, 199, 1) !important;
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-style: solid;
    text-decoration-thickness: 2px;
  }
  @media screen and (max-width: 576px) {
    font-size: 25px !important;
    line-height: 42px !important;
    width: 250px !important;
    margin-bottom: unset;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px !important;
    line-height: 45px !important;
    width: 280px !important;
    margin-bottom: unset;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 28px !important;
    line-height: 40px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 35px !important;
    line-height: 62px !important;
  }
}

.icon2 {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  @media screen and (max-width: 576px) {
    width: 23px;
    height: 23px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 30px;
    height: 30px;
  }
}
.icon3 {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  @media screen and (max-width: 576px) {
    width: 15px;
    height: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 20px;
    height: 20px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 20px;
    height: 20px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 25px;
    height: 25px;
  }
}
.dtg3 {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  line-height: 60px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #ffffff !important;
  @media screen and (max-width: 576px) {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 25px !important;
    line-height: 45px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 25px !important;
    line-height: 35px !important;
  }
}
.ltg3 {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  line-height: 60px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(36, 36, 36, 1) !important;
  @media screen and (max-width: 576px) {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 25px !important;
    line-height: 45px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 25px !important;
    line-height: 35px !important;
  }
}

.dtg4 {
  font-style: normal !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(199, 199, 199, 1) !important;
  font-family: "Poppins500" !important;
  @media screen and (max-width: 576px) {
    font-size: 13px !important;
    line-height: 19.5px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 16px !important;
    line-height: 26px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 16px !important;
    line-height: 26px !important;
  }
  @media (min-width: 1199px) and (max-width: 1400px) {
    font-size: 16px !important;
    line-height: 26px !important;
  }
}

.ltg4 {
  font-style: normal !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(36, 36, 36, 1) !important;
  font-family: "Poppins500" !important;
  @media screen and (max-width: 576px) {
    font-size: 13px !important;
    line-height: 19.5px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 16px !important;
    line-height: 26px !important;
  }
}

.icon1 {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  @media screen and (max-width: 576px) {
    width: 28px;
    height: 28px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 32px;
    height: 32px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 35px;
    height: 35px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 35px;
    height: 35px;
  }
}

.slideContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  @media screen and (max-width: 576px) {
    flex-direction: column-reverse;
    margin: unset;
    margin-top: unset;
    justify-content: flex-end;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    flex-direction: column-reverse;
    margin-top: 25px;
    margin-left: 25px;
    justify-content: flex-end;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.subTitleContainer {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  margin-left: 30px;
  @media screen and (max-width: 576px) {
    margin-top: 30px;
    margin-left: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 15px;
    margin-left: 25px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: 15px;
    margin-left: 25px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    margin-top: 15px;
    margin-left: 25px;
  }
}

.details {
  margin-top: 30px;
  margin-left: 30px;
  @media screen and (max-width: 576px) {
    margin-top: 10px;
    margin-left: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 15px;
    margin-left: 25px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: 15px;
    margin-left: 25px;
  }
  ul {
    @media screen and (max-width: 576px) {
      padding: 0 20px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      padding: 0 30px;
    }
    @media (min-width: 767px) and (max-width: 991px) {
      padding-left: 30px;
    }
    @media (min-width: 991px) and (max-width: 1199px) {
      padding-left: 30px;
    }
  }
}
.backIconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  //flex-grow: 1;
  z-index: 100;
  @media screen and (max-width: 576px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: none;
  }
}

.backIcon {
  width: 40px;
  height: 35px;
  margin: 10px 0;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    width: 20px;
    height: 20px;
    margin: 0;
    align-self: center;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 30px;
    height: 30px;
    margin: 0;
    align-self: center;  }
}

.img1 {
  width: 80% !important;
  height: auto !important;
  @media screen and (max-width: 576px) {
    width: 100% !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100% !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 80% !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 80% !important;
  }
}

.img2 {
  position: absolute !important;
  bottom: 2% !important;
  right: 28% !important;
  width: 43% !important;
  height: auto !important;
  mix-blend-mode: multiply !important;
  @media screen and (max-width: 576px) {
    bottom: 1% !important;
    right: 21% !important;
    width: 55% !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    bottom: 1% !important;
    right: 21% !important;
    width: 55% !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    bottom: 5% !important;
    right: 30% !important;
    width: 42% !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    bottom: 2% !important;
    right: 28% !important;
    width: 43% !important;
  }
}

.img3 {
  position: absolute !important;
  top: 39% !important;
  left: 15% !important;
  width: 15% !important;
  height: auto !important;
  @media screen and (max-width: 576px) {
    top: 47% !important;
    left: 3% !important;
    width: 18% !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: 47% !important;
    left: 3% !important;
    width: 18% !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    position: absolute !important;
    top: 35% !important;
    left: 18% !important;
    width: 15% !important;
    height: auto !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    top: 39% !important;
    left: 15% !important;
    width: 15% !important;
  }
}
.img4 {
  position: absolute !important;
  bottom: -3% !important;
  right: 28% !important;
  width: 43% !important;
  height: auto !important;
  z-index: -1 !important;
  @media screen and (max-width: 576px) {
    bottom: -5% !important;
    right: 21% !important;
    width: 55% !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    bottom: -5% !important;
    right: 21% !important;
    width: 55% !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    bottom: 2% !important;
    right: 30% !important;
    width: 42% !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    bottom: -3% !important;
    right: 28% !important;
    width: 43% !important;
  }
}
.img5 {
  position: absolute !important;
  top: 51% !important;
  left: 3% !important;
  width: 40% !important;
  height: auto !important;
  z-index: -1 !important;
  mix-blend-mode: multiply;
  transform: rotate(-15deg);

  @media screen and (max-width: 576px) {
    top: 58% !important;
    left: -3% !important;
    width: 40% !important;
    transform: rotate(-5deg);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: 58% !important;
    left: -3% !important;
    width: 40% !important;
    transform: rotate(-5deg);
  }
  @media (min-width: 767px) and (max-width: 991px) {
    top: 48.5% !important;
    left: 7% !important;
    width: 37% !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    top: 51% !important;
    left: 3% !important;
    width: 40% !important;
    transform: rotate(-15deg);
  }
}
.img6 {
  position: absolute !important;
  bottom: 10% !important;
  right: 7% !important;
  width: 21% !important;
  transform: rotate(-12deg);
  height: auto !important;
  z-index: -1 !important;
  mix-blend-mode: multiply !important;

  @media screen and (max-width: 576px) {
    bottom: 8% !important;
    right: -10% !important;
    width: 35% !important;
    transform: rotate(-20deg);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    bottom: 8% !important;
    right: -10% !important;
    width: 35% !important;
    transform: rotate(-20deg);
  }
  @media (min-width: 767px) and (max-width: 991px) {
    bottom: 12% !important;
    right: 13% !important;
    width: 18% !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    bottom: 10% !important;
    right: 7% !important;
    width: 21% !important;
    transform: rotate(-12deg);
  }
}
.imgContainer {
  z-index: 1;
  padding-left: 30px !important;
  position: relative;
  width: 40%;
  @media screen and (max-width: 576px) {
    width: 70%;
    height: auto;
    padding-left: unset;
    margin-bottom: 70px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 70%;
    height: auto;
    padding-left: unset;
    margin-bottom: 90px;
    //transform: translate(0px, -80px);
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 40%;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 40%;
    height: auto;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 40%;
    height: auto;
  }
}
.imgContainer1 {
  z-index: 1;
  padding-left: 30px !important;
  position: relative;
  width: 40%;
  @media screen and (max-width: 576px) {
    width: 70%;
    height: auto;
    padding-left: unset;
    margin-bottom: 70px;
    margin-top: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 70%;
    height: auto;
    padding-left: unset;
    margin-bottom: 50px;
    //transform: translate(0px, -80px);
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 40%;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 40%;
    height: auto;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 40%;
    height: auto;
  }
}
.img11 {
  width: 100% !important;
  height: auto !important;
  @media screen and (max-width: 576px) {
    width: 100% !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100% !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 100% !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 100% !important;
  }
}

.img21 {
  position: absolute !important;
  bottom: 8% !important;
  right: 20% !important;
  width: 32% !important;
  transform: rotate(-50deg);
  height: auto !important;
  mix-blend-mode: multiply !important;
  @media screen and (max-width: 576px) {
    bottom: 8% !important;
    right: 20% !important;
    width: 32% !important;
    transform: rotate(-50deg);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    bottom: 8% !important;
    right: 20% !important;
    width: 32% !important;
    transform: rotate(-50deg);
  }
  @media (min-width: 767px) and (max-width: 991px) {
    bottom: 8% !important;
    right: 20% !important;
    width: 32% !important;
    transform: rotate(-50deg);
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    bottom: 8% !important;
    right: 20% !important;
    width: 32% !important;
    transform: rotate(-50deg);
  }
}

.img31 {
  position: absolute !important;
  top: -3% !important;
  left: 18% !important;
  width: 15% !important;
  height: auto !important;
  @media screen and (max-width: 576px) {
    top: -3% !important;
    left: 18% !important;
    width: 15% !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: -3% !important;
    left: 18% !important;
    width: 15% !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    top: -3% !important;
    left: 18% !important;
    width: 15% !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    top: -3% !important;
    left: 18% !important;
    width: 15% !important;
  }
}
.img41 {
  display: none !important;
}
.img51 {
  position: absolute !important;
  top: 35% !important;
  left: 7% !important;
  width: 40% !important;
  transform: rotate(-15deg);
  height: auto !important;
  z-index: -1 !important;
  mix-blend-mode: multiply;

  @media screen and (max-width: 576px) {
    top: 35% !important;
    left: 7% !important;
    width: 40% !important;
    transform: rotate(-15deg);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: 35% !important;
    left: 7% !important;
    width: 40% !important;
    transform: rotate(-15deg);
  }
  @media (min-width: 767px) and (max-width: 991px) {
    top: 35% !important;
    left: 7% !important;
    width: 40% !important;
    transform: rotate(-15deg);
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    top: 35% !important;
    left: 7% !important;
    width: 40% !important;
    transform: rotate(-15deg);
  }
}
.img61 {
  position: absolute !important;
  top: 19% !important;
  right: 9% !important;
  width: 25% !important;
  transform: rotate(-72deg);
  height: auto !important;
  mix-blend-mode: multiply !important;

  @media screen and (max-width: 576px) {
    top: 19% !important;
    right: 9% !important;
    width: 25% !important;
    transform: rotate(-72deg);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    top: 19% !important;
    right: 9% !important;
    width: 25% !important;
    transform: rotate(-72deg);
  }
  @media (min-width: 767px) and (max-width: 991px) {
    top: 19% !important;
    right: 9% !important;
    width: 25% !important;
    transform: rotate(-72deg);
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    top: 19% !important;
    right: 9% !important;
    width: 25% !important;
    transform: rotate(-72deg);
  }
}
.imgContainer1 {
  z-index: 1;
  padding-left: 30px !important;
  position: relative;
  width: 40%;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: auto;
    padding-left: unset;
    margin-top: 50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    height: auto;
    padding-left: unset;
    margin-top: 50px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 40%;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 40%;
    height: auto;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 40%;
    height: auto;
  }
}
.textContainer {
  width: 60%;
  margin-left: 100px;
  margin-right: 20px;
  @media screen and (max-width: 576px) {
    margin-left: unset;
    margin-right: unset;
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-left: unset;
    margin-right: unset;
    width: 100%;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-left: 20px;
    margin-right: 10px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    margin-left: 20px;
    margin-right: 10px;
  }
}

.iconContainer {
  display: none;
  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    justify-content: space-between;
    margin-right: 10px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    justify-content: space-between;
    margin-right: 10px;
  }

}

.icon {
  width: 40px;
  height: 20px;
  @media (min-width: 576px) and (max-width: 767px) {
    width: 50px;
    height: 30px;
  }
}
