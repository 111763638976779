.tg1 {
  font-family: "Poppins600" !important;
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 36px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(36, 36, 36, 1) !important;

  @media screen and (max-width: 576px) {
    display: flex;
    align-items: center;
    font-size: 20px !important;
    line-height: 30px !important;
  }
}

.tg2 {
  font-family: "Poppins900" !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(151, 71, 255, 1) !important;
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
    line-height: 21px !important;
  }
}

.tg3 {
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(64, 64, 64, 1) !important;
  font-family: "Poppins500" !important;
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
    line-height: 21px !important;
  }
}

.tg4 {
  font-family: "Poppins900" !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: rgba(64, 64, 64, 1) !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
    line-height: 21px !important;
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }
}

.tg5 {
  font-family: "Poppins900" !important;
  font-size: 24px !important;
  line-height: 36px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  @media screen and (max-width: 576px) {
    font-size: 20px !important;
    line-height: 20px !important;
    margin-right: 10px !important;
  }
}

.clientDetailsContainer {
  margin: 30px;
  margin-left: 60px;
  margin-right: 60px;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
  padding: 20px 10px;
  display: flex;
  border-radius: 30px;
  @media screen and (max-width: 576px) {
    margin: unset;
    flex-direction: column;
    margin-bottom: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin: unset;
    flex-direction: column;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin: unset;
    flex-direction: column;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    margin: unset;
    flex-direction: column;
  }
}
.tgContainer {
  min-width: 300px;
  margin: 20px !important;
  margin-top: 40px !important;
  @media screen and (max-width: 576px) {
    min-width: unset;
    margin: 10px !important;
    margin-top: 20px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: unset !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: unset !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    margin-top: unset !important;
  }
}
.img1 {
  width: 94px;
  height: 68px;
  @media screen and (max-width: 576px) {
    width: 60px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 70px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 94px;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 94px;
    height: auto;
  }
}

#imagecarousel {
  margin: 20px;
  @media screen and (max-width: 576px) {
    margin: unset;
  }
}
.backIconMobile {
  display: none;
  @media screen and (max-width: 576px) {
    display: unset;
    width: 20px;
    height: 20px;
    align-self: flex-end;
    margin-right: 10px;
  }
}
