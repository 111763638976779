.container {
  background-image: url("../../../../public/assets/images/homePage/bg5.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  padding-top: 5em;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 576px) {
    min-height: max-content;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-height: max-content;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    padding-top: 4em;
    padding-bottom: 4em;
    min-height: max-content;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    padding-top: 4em;
    padding-bottom: 4em;
    min-height: max-content;
  }
}

.tg1 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 64px !important;
  line-height: 96px !important;
  color: #03a197 !important;
  text-align: start;
  &:hover,
  &:active,
  &:focus {
    color: #f2e024 !important;
  }
  @media screen and (max-width: 320px) {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 25px !important;
    line-height: 30px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px !important;
    line-height: 35px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 35px !important;
    line-height: 45px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 45px !important;
    line-height: 55px !important;
  }
}

.icon1 {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  @media screen and (max-width: 576px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 35px;
    height: 35px;
  }
}

.tg2 {
  font-size: 16px !important;
  line-height: 24px !important;
  color: white !important;
  text-align: start;
  padding-left: 20px;
  padding-top: 30px;
  font-family: "Poppins500" !important;
  @media screen and (max-width: 576px) {
    padding-left: unset;
    padding-top: 5px;
    font-size: 13px !important;
    line-height: 19.5px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 10px;
    padding-top: 20px;
    font-size: 14px !important;
    line-height: 20px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    padding-left: 10px;
    padding-top: 20px;
    font-size: 18px !important;
    line-height: 30px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    padding-left: 10px;
    padding-top: 20px;
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.btn,
.btn:hover,
.btn:active,
.btn:focus {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 48px !important;
  text-align: justify !important;
  color: #ffffff !important;
  background: #0aadb7 !important;
  box-shadow: 0px 0px 7px #f2e024 !important;
  border-radius: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 10px 40px !important;
  gap: 20px !important;
  justify-content: center;
  @media screen and (max-width: 576px) {
    font-size: 20px !important;
    line-height: 30px !important;
    padding: 5px 20px !important;
    align-items: center !important;
    gap: 10px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px !important;
    line-height: 38px !important;
    padding: 5px 20px !important;
    align-items: center !important;
    gap: 10px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 22px !important;
    line-height: 38px !important;
    padding: 5px 20px !important;
    align-items: center !important;
    gap: 10px !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 28px !important;
    line-height: 40px !important;
    padding: 10px 40px !important;
    align-items: center !important;
    gap: 10px !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }
}

.endIcon {
  width: 45.6px;
  height: 48px;
  @media screen and (max-width: 576px) {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 35px;
    height: 35px;
  }
}

.textContainer {
  display: flex;
  align-items: flex-start;
  padding: 50px;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    padding: 0 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 20px 50px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    padding: 20px 50px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    padding: 50px;
  }
}

.content {
  display: flex;
  align-items: center;
  @media screen and (max-width: 576px) {
    flex-direction: column-reverse;
  }
}

.img1 {
  width: unset;
  height: unset;
  @media screen and (max-width: 320px) {
    width: 70%;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 50%;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 240px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 300px;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 450px;
    height: auto;
  }
}

.img2 {
  @media screen and (max-width: 576px) {
    width: 40px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 30px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 35px;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 45px;
    height: auto;
  }
}

.titleContainer {
  padding-left: 50px;
  @media screen and (max-width: 576px) {
    padding-left: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 20px;
  }
}

.link {
  text-decoration: none !important;
}
