.section2 {
  background-image: url("../../../../public/assets/images/homePage/bg1mobile.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  padding: 30px 60px;
  flex-direction: column;
  background-position: top;
  @media screen and (max-width: 420px) {
    padding: 20px;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    padding: 20px;
  }
}

.tg1 {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-size: 36px !important;
  line-height: 54px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: rgba(47, 54, 129, 1) !important;
  margin: 20px !important;
  @media screen and (max-width: 420px) {
    font-size: 14px !important;
    line-height: 24px !important;
    margin: 10px !important;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    font-size: 4vw !important;
    line-height: 24px !important;
    margin: 10px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 24px !important;
    line-height: 32px !important;
    margin: 20px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 28px !important;
    line-height: 38px !important;
    margin: 20px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 32px !important;
    line-height: 42px !important;
    margin: 20px !important;
  }
}

.img1 {
  width: 143px;
  height: 53px;
  @media screen and (max-width: 576px) {
    width: 53px;
    height: auto;
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 73px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 93px;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 113px;
    height: auto;
  }
}

.img2 {
  display: none;
  @media screen and (max-width: 420px) {
    width: 40px;
    height: auto;
    display: unset;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    width: 60px;
    height: auto;
    display: unset;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  @media screen and (max-width: 576px) {
    margin-bottom: unset;
  }
}

.video {
  width: 100%;
  height: auto;
}
