.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  .image {
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(47, 54, 129, 1);
    mix-blend-mode: hard-light;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .text {
    font-family: "Poppins700" !important;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
  }
  .zoom {
    transform: scale(1.1);
  }
  &:hover .image {
    transform: scale(1.2);
  }
  &:hover .overlay {
    opacity: 1 !important;
    z-index: 1;
  }
}
