.container {
  background-image: url("../../../../public/assets/images/homePage/bg5.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  @media screen and (max-width: 320px) {
    padding: 20px;
    padding-top: unset;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    padding: 20px;
    padding-top: unset;
  }
}

.tg1 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 64px !important;
  line-height: 96px !important;
  text-align: center !important;
  color: #ffffff !important;
  @media screen and (max-width: 576px) {
    font-size: 24px !important;
    line-height: 96px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px !important;
    line-height: 96px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 40px !important;
    line-height: 96px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 50px !important;
    line-height: 96px !important;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 60px !important;
    line-height: 96px !important;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    font-size: 64px !important;
    line-height: 96px !important;
  }
}

.tg2 {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #c7c7c7 !important;
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
    line-height: 24px !important;
  }
}

.tg3 {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  text-align: center !important;
  color: #c7c7c7 !important;
  @media screen and (max-width: 576px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.mindset {
  margin-top: 50px;
  margin-bottom: 30px;
  @media screen and (max-width: 320px) {
    margin-top: unset;
    margin-bottom: unset;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    margin-top: unset;
    margin-bottom: unset;
  }
}

.objectifs {
  margin-top: 40px;
  margin-bottom: 20px;
  @media screen and (max-width: 576px) {
    margin-top: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 35px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: 45px;
  }
}
