.footer {
  background-image: url("../../../public/assets/images/homePage/bg2.png");
  display: flex;
  background-size: cover;
  input {
    background: #d9d9d9;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    height: 46px;
    @media screen and (max-width: 320px) {
      border-radius: 10px;
      height: 36px;
    }
    @media (min-width: 320px) and (max-width: 576px) {
      border-radius: 10px;
      height: 36px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      border-radius: 10px;
      height: 36px;
    }
  }
  img {
    margin-right: 30px;
    @media screen and (max-width: 320px) {
      width: 60px;
    }
    @media (min-width: 320px) and (max-width: 576px) {
      width: 60px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 70px;
    }
    @media (min-width: 767px) and (max-width: 991px) {
      width: 70px;
    }
    @media (min-width: 991px) and (max-width: 1199px) {
      width: 70px;
    }
  }
}

.container {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 320px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  margin-right: 20px;
  @media screen and (max-width: 320px) {
    margin-right: unset;
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    margin-right: unset;
    width: 100%;
  }
}

.tg1 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 150% !important;
  letter-spacing: -0.019em !important;
  color: #ffffff !important;
  text-align: start !important;
  margin-bottom: 10px !important;
  @media screen and (max-width: 320px) {
    font-size: 15px !important;
    line-height: 150% !important;
    margin-bottom: 5px !important;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 15px !important;
    line-height: 150% !important;
    margin-bottom: 5px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px !important;
    line-height: 150% !important;
    margin-bottom: 5px !important;
  }
}
.tg2 {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 150% !important;
  letter-spacing: -0.019em !important;
  color: #ffffff !important;
  text-align: start !important;
  margin-top: 10px !important;
  @media screen and (max-width: 320px) {
    font-size: 15px !important;
    line-height: 150% !important;
    margin-top: 5px !important;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 15px !important;
    line-height: 150% !important;
    margin-top: 5px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px !important;
    line-height: 150% !important;
    margin-bottom: 5px !important;
  }
}
.tg3 {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  letter-spacing: -0.011em !important;
  color: #ffffff !important;
  text-align: start !important;
  margin-top: 20px !important;
  font-family: "Poppins400" !important;
  @media screen and (max-width: 320px) {
    font-size: 15px !important;
    line-height: 20px !important;
    margin-top: 10px !important;
    display: none;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 15px !important;
    line-height: 20px !important;
    margin-top: 10px !important;
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px !important;
    line-height: 20px !important;
    margin-top: 10px !important;
  }
}
.tg4 {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  letter-spacing: -0.011em !important;
  color: #ffffff !important;
  text-align: start !important;
  margin-top: 20px !important;
  font-family: "Poppins400" !important;
  display: none;
  @media screen and (max-width: 320px) {
    font-size: 15px !important;
    line-height: 20px !important;
    margin: 0 auto !important;
    margin-top: 5px !important;
    display: unset;
    text-align: center !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 15px !important;
    line-height: 20px !important;
    margin: 0 auto !important;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
    display: unset;
    text-align: center !important;
  }
}
.carousel {
  width: 100%;
  max-width: 450px;
  border-radius: 45px;
  @media screen and (max-width: 576px) {
    margin-top: 20px;
    width: 100%;
    max-width: unset;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 20px;
    width: 100%;
    max-width: unset;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    max-width: 350px;
  }
}

.menuItem {
  font-family: "Poppins600" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
}
.menuItem:hover,
:active,
:focus {
  color: #f2e024;
}

.navlink {
  text-decoration: none !important;
}
.appbar {
  height: 132px !important;
  justify-content: center !important;
  background-color: rgba(29, 29, 29, 0.29) !important;
  border-radius: 40px !important;
  padding: 30px !important;
}

.menuItem {
  font-family: "Poppins600" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  .endIcon {
    display: none !important;
  }
}

.menuItem:hover,
:active,
:focus {
  color: #f2e024;
}

.menuItemExpertise:hover,
:active,
:focus {
  .endIcon {
    display: inherit !important;
  }
}

.navlink {
  text-decoration: none !important;
}
.subMenu {
  margin-left: 10px;
  background-color: transparent !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    li {
      background: rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(65, 65, 65, 0.5);
      backdrop-filter: blur(2px);
      font-style: normal;
      font-weight: 400;
      font-size: 12px !important;
      line-height: 24px;
      color: #ffffff;
      font-family: "Poppins400" !important;
    }
    li:hover,
    :active,
    :focus {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.subMenu2 {
  background-color: transparent !important;
  opacity: 0 !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    li {
      background: rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(65, 65, 65, 0.5);
      backdrop-filter: blur(2px);
      font-style: normal;
      font-weight: 400;
      font-size: 12px !important;
      line-height: 24px;
      color: #ffffff;
      font-family: "Poppins400" !important;
    }
    li:hover,
    :active,
    :focus {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.icon {
  width: 17px;
  height: 17px;
}

.navbar {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  @media screen and (max-width: 320px) {
    justify-content: space-between;
    margin-top: 20px;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    justify-content: space-between;
    margin-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    justify-content: space-between;
  }
  @media (min-width: 767px) and (max-width: 899px) {
    justify-content: space-between;
  }
}

.menuIcon {
  width: 45px !important;
  height: 45px !important;
  @media screen and (max-width: 576px) {
    width: 35px !important;
    height: 35px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 35px !important;
    height: 35px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 35px !important;
    height: 35px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 35px !important;
    height: 35px !important;
  }
}

.input {
  width: 100% !important;
  color: black !important;
  font-size: 18px !important;
  padding: 5px 10px !important;
  display: flex !important;
}
.subMenuText {
  font-size: 12px !important;
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #ffffff !important;
  text-align: center !important;
}

.form {
  margin-right: 20px;
}

.mainContainer {
  @media (min-width: 900px) and (max-width: 991px) {
    padding: 34px !important;
  }
  @media screen and (min-width: 991px) {
    width: 85% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 0 !important;
  }
}

.socialMediaContainer {
  margin-top: 20px;
  align-self: flex-start;
  @media screen and (max-width: 576px) {
    display: none;
  }
}

.socialMediaContainerMobile {
  margin-top: 20px;
  display: none;
  @media screen and (max-width: 576px) {
    display: unset;
    margin: 20px auto;
    margin-bottom: 0;
  }
}

.socialMediaIcon {
  width: 38px;
  height: 38px;
  margin-right: 30px;
  @media screen and (max-width: 576px) {
    margin-right: 10px;
    width: 23px;
    height: 23px;
  }
  @media (min-width: 576px) and (max-width: 991px) {
    width: 30px;
    height: 30px;
    margin-right: 25px;
  }
}
