.appbar {
  height: 132px !important;
  justify-content: center !important;
  background-color: rgba(29, 29, 29, 0.29) !important;
  border-radius: 40px !important;
  padding: 30px !important;
  max-width: 1600px;
  left: 50% !important;
  transform: translate(-50%);
  @media screen and (max-width: 576px) {
    height: 80px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 90px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    height: 100px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    height: 110px !important;
  }
}

.menuItem {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  text-align: center !important;
  .endIcon {
    display: none !important;
  }
}

.menuItem:hover,
:active,
:focus {
  color: #f2e024;
}

.menuItemExpertise:hover,
:active,
:focus {
  .endIcon {
    display: inherit !important;
  }
}

.navlink {
  text-decoration: none !important;
}

.subMenu {
  background-color: transparent !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    li {
      background: rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(65, 65, 65, 0.5);
      backdrop-filter: blur(2px);
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 24px !important;
      color: #ffffff !important;
      font-family: "Poppins400" !important;
    }
    li:hover,
    :active,
    :focus {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.subMenu2 {
  background-color: transparent !important;
  opacity: 0 !important;
  ul {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    li {
      background: rgba(255, 255, 255, 0.3);
      border-bottom: 1px solid rgba(65, 65, 65, 0.5);
      backdrop-filter: blur(2px);
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 24px !important;
      color: #ffffff !important;
      font-family: "Poppins400" !important;
    }
    li:hover,
    :active,
    :focus {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.icon {
  width: 17px;
  height: 17px;
}

.subMenuText {
  font-size: 12px !important;
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: #ffffff !important;
  text-align: center !important;
}

.logo {
  width: 161px;
  height: auto;
  @media screen and (max-width: 576px) {
    width: 80px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 90px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 100px;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 110px;
    height: auto;
  }
}
