.errorText {
  color: #d32f2f;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 1.66 !important;
  letter-spacing: 0.03333em !important;
  text-align: left !important;
  margin-top: 3px !important;
  margin-right: 14px !important;
  margin-bottom: 0 !important;
  margin-left: 14px !important;
}

.btnContainer {
  justify-content: flex-end !important;
}

.headingTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.deleteBtn {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  margin: 8px !important;
  background-color: #ffffffab !important;
  :hover {
    color: rgba(153, 32, 32, 0.921) !important;
  }
}
