.section1 {
  background-image: url("../../../../public/assets/images/homePage/bg2.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 30px;
  padding-top: 132px;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  @media screen and (max-width: 576px) {
    min-height: max-content;
    flex-direction: column;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-height: max-content;
    padding-bottom: 0 !important;
    padding-right: 30px !important;
  }

  @media (min-width: 767px) and (max-width: 991px) {
    min-height: max-content;
    padding-top: 132px !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }
  @media (min-width: 991px) and (max-width: 1200px) {
    min-height: max-content;
    padding-top: 132px !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }
  @media (min-width: 1200px) {
    min-height: max-content;
    padding: 30px;
    padding-top: 132px !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
  }
}

.tgContainer {
  text-align: start;
  align-self: center;
  margin-left: 40px;
  @media screen and (max-width: 576px) {
    text-align: start;
    margin-bottom: 30px;
    margin-left: unset;
    padding: 0 30px;
    align-self: flex-start;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    justify-content: center;
    margin-left: unset;
  }
}

.tg1 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 64px !important;
  line-height: 96px !important;
  color: transparent !important;
  -webkit-text-stroke: 1px #ffffff;
  margin-bottom: 30px !important;
  @media screen and (max-width: 576px) {
    font-size: 30px !important;
    line-height: 44px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 24px !important;
    line-height: 36px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 34px !important;
    line-height: 56px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 991px) and (max-width: 1200px) {
    font-size: 44px !important;
    line-height: 56px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 54px !important;
    line-height: 66px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    font-size: 64px !important;
    line-height: 76px !important;
  }
}

.tg2 {
  font-family: "Poppins700" !important;
  color: #ffffff !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 64px !important;
  line-height: 96px !important;
  margin-bottom: 30px !important;

  @media screen and (max-width: 576px) {
    font-size: 30px !important;
    line-height: 44px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 24px !important;
    line-height: 36px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 34px !important;
    line-height: 56px !important;
    margin-bottom: 10px !important;
  }
  @media (min-width: 991px) and (max-width: 1200px) {
    font-size: 44px !important;
    line-height: 56px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 54px !important;
    line-height: 66px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    font-size: 64px !important;
    line-height: 76px !important;
  }
}

.img1 {
  width: 90%;
  height: auto;
  @media screen and (max-width: 576px) {
    width: 90%;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1200px) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 90%;
    height: auto;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    width: 90%;
    height: auto;
  }
}

.img2 {
  margin-right: 20px;
  width: 153px;
  height: auto;
  @media screen and (max-width: 576px) {
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 103px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 113px;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1200px) {
    width: 123px;
    height: auto;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 133px;
    height: auto;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    width: 143px;
    height: auto;
  }
}

.imgContainer {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  @media screen and (max-width: 576px) {
    justify-content: center;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    justify-content: center;
  }
}
