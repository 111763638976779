@font-face {
  font-family: "Poppins900";
  src: url("./Poppins-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins800";
  src: url("./Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins700";
  src: url("./Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins600";
  src: url("./Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins500";
  src: url("./Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins400";
  src: url("./Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins300";
  src: url("./Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins200";
  src: url("./Poppins-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins100";
  src: url("./Poppins-Thin.ttf") format("truetype");
}
