.section2 {
  background-image: url("../../../../public/assets/images/homePage/bg1.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  background-position: top;
  @media screen and (max-width: 576px) {
    padding: 20px;
    min-height: max-content;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-height: max-content;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    min-height: max-content;
  }
}

.tg1 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-size: 40px !important;
  line-height: 60px !important;
  color: #000000 !important;
  @media screen and (max-width: 320px) {
    font-size: 20px !important;
    line-height: 25px !important;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 25px !important;
    line-height: 25px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px !important;
    line-height: 50px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 35px !important;
    line-height: 60px !important;
  }
}

.formContainer {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  width: 80%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 576px) {
    width: 90%;
    padding: 14px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 85%;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 80%;
  }
}

.input {
  padding: 10px 10px 10px 20px;
  flex-grow: 1;
  height: 50px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0.86;
  border: 1px solid #ffffff;
  border-radius: 20px;
  font-family: "Poppins500" !important;
  font-style: normal;
  font-size: 20px;
  line-height: 30px;
  color: rgba(100, 100, 100, 0.7);
  margin: 10px 0;
  @media screen and (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
    height: 30px;
    padding: 10px 10px 10px 10px;
    margin: 5px 0;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
    line-height: 30px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 18px;
    line-height: 30px;
  }
}

.textarea {
  padding: 10px 10px 10px 20px;
  flex-grow: 1;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0.86;
  border: 1px solid #ffffff;
  border-radius: 20px;
  font-family: "Poppins500" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: rgba(100, 100, 100, 0.7);
  @media screen and (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
    padding: 10px 10px 10px 10px;
    margin: 5px 0;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
    line-height: 30px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 18px;
    line-height: 30px;
  }
}

.checkboxContainer {
  width: 80%;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 576px) {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.label {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
  color: #000000;
  font-family: "Poppins400" !important;
  &:hover {
    color: #000000 !important;
    text-decoration: none !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px !important;
    line-height: 15px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 18px !important;
    line-height: 30px !important;
  }
}

.btn {
  padding: 15px 35px !important;
  background: #13a89d !important;
  border-radius: 20px !important;
  font-style: normal !important;
  font-size: 28px !important;
  line-height: 28px !important;
  text-align: justify !important;
  color: #ffffff !important;
  font-family: "Poppins500" !important;
  text-transform: none !important;

  @media screen and (max-width: 576px) {
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 10px 25px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px !important;
    line-height: 28px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 25px !important;
    line-height: 38px !important;
  }
  &:hover {
    padding: 20px 50px !important;
    background: #0b7177 !important;
    border-radius: 20px !important;
    @media screen and (max-width: 576px) {
      padding: 15px 30px !important;
    }
  }
  &:active,
  :focus {
    padding: 20px 50px !important;
    background: #274754 !important;
    border-radius: 20px !important;
  }
}

.img1 {
  width: 70px;
  height: 70px;
  @media screen and (max-width: 320px) {
    width: 30px;
    height: 30px;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 40px;
    height: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 50px;
    height: 50px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 60px;
    height: 60px;
  }
}

.img2 {
  width: 561px;
  height: 561px;
  @media screen and (max-width: 320px) {
    width: 230px;
    height: 230px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    width: 300px;
    height: 300px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 361px;
    height: 361px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 461px;
    height: 461px;
  }
}

.contactsContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 130px;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media screen and (max-width: 576px) {
    margin-top: 30px;
    justify-content: center;
    align-items: flex-start;
  }
}

.tgContact {
  font-family: "Poppins600" !important;
  text-align: start;
  font-size: 24px !important;
  line-height: 36px !important;
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
    line-height: 28px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 18px !important;
    line-height: 35px !important;
  }
}

.contactContainer {
  display: flex;
  margin-bottom: 40px;
  align-items: flex-start !important;
  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-bottom: 20px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-bottom: 20px;
  }
}

.icon1 {
  width: 20px;
  height: 20px;
}

.icon2 {
  width: 20px;
  height: 23px;
}

.errorText {
  color: red;
  margin-top: 5px;
  font-size: 20px;
  text-align: start;
}

.underline {
  text-decoration: underline;
}

.img3 {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -30%);
  @media screen and (max-width: 576px) {
    width: 60px;
    height: auto;
    top: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 120px;
    height: auto;
    top: 15px;
    left: 50%;
    transform: translate(-50%, -30%);
  }
}

.img5 {
  position: absolute;
  bottom: 50px;
  left: 120px;
  transform: translate(-50%, -30%);
  mix-blend-mode: multiply;
  @media screen and (max-width: 576px) {
    width: 35px;
    height: auto;
    position: absolute;
    bottom: 20px;
    left: 55px;
    transform: translate(-50%, -30%);
    mix-blend-mode: multiply;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 50px;
    height: auto;
    position: absolute;
    bottom: 30px;
    left: 80px;
    transform: translate(-50%, -30%);
    mix-blend-mode: multiply;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    bottom: 30px;
    left: 100px;
    width: 70px;
  }
}
.img6 {
  position: absolute;
  bottom: 5px;
  right: -40px;
  transform: translate(-50%, -30%);
  z-index: -1;
  @media screen and (max-width: 576px) {
    width: 80px;
    height: auto;
    position: absolute;
    bottom: 1px;
    right: -18px;
    transform: translate(-50%, -30%);
    z-index: -1;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 124px;
    height: auto;
    position: absolute;
    bottom: -3px;
    right: -30px;
    transform: translate(-50%, -30%);
    z-index: -1;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 150px;
    height: auto;
  }
}

.imgContainer {
  position: relative;
}
