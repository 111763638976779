.prevArrow {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 2.5rem;
  border: none;
  border-radius: 50%;
  padding: 5px;
  z-index: 2;
  &:hover {
    font-size: 3.5rem;
    @media screen and (max-width: 576px) {
      font-size: 1.8rem;
      padding-right: 0;
    }
  }
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
    padding-left: 0;
  }
}

.nextArrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 2.5rem;
  border: none;
  border-radius: 50%;
  padding: 5px;
  z-index: 2;
  &:hover {
    font-size: 3.5rem;
    @media screen and (max-width: 576px) {
      font-size: 1.8rem;
      padding-right: 0;
    }
  }
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
    padding-right: 0;
  }
}

.carousel.carousel-slider {
  border-radius: 50px;
  width: 80%;
}
