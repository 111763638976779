.container {
  background-image: url("../../../../public/assets/images/homePage/bg1.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: top;
  @media screen and (max-width: 576px) {
    min-height: max-content;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  @media (min-width: 577px) and (max-width: 767px) {
    min-height: max-content;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    min-height: max-content;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    padding-top: 4em;
    padding-bottom: 4em;
    min-height: max-content;
  }
  ul {
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    color: #242424;
    font-family: "Poppins500" !important;
    @media screen and (max-width: 576px) {
      padding-left: 30px;
      font-size: 13px;
      line-height: 19.5px;
    }
  }
}

.tg2 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 64px !important;
  line-height: 96px !important;
  color: #03a197 !important;
  text-align: start !important;
  &:hover,
  &:active,
  &:focus {
    color: #f2e024 !important;
  }
  @media screen and (max-width: 320px) {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 25px !important;
    line-height: 30px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px !important;
    line-height: 35px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 35px !important;
    line-height: 45px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 45px !important;
    line-height: 55px !important;
  }
}

.icon1 {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  @media screen and (max-width: 576px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 25px;
    height: 25px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 35px;
    height: 35px;
  }
}
.title {
  padding-left: 50px;
  @media screen and (max-width: 576px) {
    padding-left: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 20px;
  }
}
.tg3 {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #242424 !important;
  text-align: start !important;
  @media screen and (max-width: 576px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 24px !important;
    line-height: 35px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 20px !important;
    line-height: 35px !important;
  }
}

.textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  width: 85%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 100%;
  }
}

.tg4 {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-size: 15px !important;
  line-height: unset !important;
  color: #2f3681 !important;
  text-align: start;
  @media screen and (max-width: 360px) {
    font-size: 3.5vw !important;
  }
  @media (min-width: 360px) and (max-width: 576px) {
    font-size: 10px !important;
  }
}
.tg5 {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 28px !important;
  line-height: 29px !important;
  color: #2f3681 !important;
  text-align: start;
  @media screen and (max-width: 360px) {
    font-size: 6vw !important;
    line-height: 20px !important;
  }
  @media (min-width: 360px) and (max-width: 576px) {
    font-size: 18px !important;
    line-height: 20px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 24px !important;
    line-height: 29px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 24px !important;
    line-height: 29px !important;
  }
}
.tg6 {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 60px !important;
  line-height: 96px !important;
  text-align: center !important;
  color: #2f3681 !important;
  @media screen and (max-width: 360px) {
    font-size: 12vw !important;
    line-height: 30px !important;
  }
  @media (min-width: 360px) and (max-width: 576px) {
    font-size: 35px !important;
    line-height: 30px !important;
  }
}

.textContainer2 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 30px;
  width: 550px;
  @media screen and (max-width: 360px) {
    justify-content: flex-end;
    margin-right: unset;
    margin-bottom: unset;
    width: 100%;
  }
  @media (min-width: 360px) and (max-width: 576px) {
    justify-content: flex-end;
    margin-right: 20px;
    width: 330px;
  }
  @media (min-width: 577px) and (max-width: 767px) {
    width: 550px;
    margin-left: 20px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 550px;
    margin-left: 20px;
  }
}

.img3 {
  width: 140px;
  position: absolute;
  top: 40px;
  left: 0px;
  @media screen and (max-width: 360px) {
    display: none;
  }
  @media (min-width: 360px) and (max-width: 576px) {
    width: 100px;
    top: 10px;
    left: 29px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 120px;
    top: 40px;
    left: 4px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 120px;
    top: 40px;
    left: 4px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 120px;
  }
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
  margin-top: 20px;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (min-width: 577px) and (max-width: 767px) {
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    flex-direction: row;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.content2 {
  padding-right: 20px;
  @media screen and (max-width: 576px) {
    margin-top: unset;
    padding-right: unset;
  }
  @media (min-width: 577px) and (max-width: 767px) {
    margin-top: unset;
    padding-right: unset;
    padding-left: 30px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: unset;
    padding-right: unset;
    padding-left: 30px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    padding-right: unset;
  }
}

.img4 {
  width: 50%;
  height: auto;
  @media screen and (max-width: 576px) {
    width: 80%;
    height: auto;
  }
  @media (min-width: 577px) and (max-width: 767px) {
    width: 80%;
    height: auto;
    margin: 20px 0;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 70%;
    height: auto;
    margin: 20px 0;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 45%;
    height: auto;
    margin: 20px 0;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 50%;
    height: auto;
    margin: 20px 0;
  }
}

.img5 {
  text-align: end;
  position: absolute;
  margin-left: 20px;
  @media screen and (max-width: 576px) {
    width: 35px;
    height: auto;
    margin-left: 10px;
  }
  @media (min-width: 577px) and (max-width: 767px) {
    width: 35px;
    height: auto;
    margin-left: 10px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 35px;
    height: auto;
    margin-left: 10px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 30px;
    height: auto;
  }
}
