.section1 {
  background-image: url("../../../../public/assets/images/homePage/bg2.png");
  background-size: cover;
  overflow: auto;
  padding: 30px;
  min-height: max-content;
  background-position: right;
  @media screen and (max-width: 576px) {
    padding: 0;
    min-height: max-content;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0;
    min-height: max-content;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    min-height: max-content;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    min-height: max-content;
  }
}

.container {
  margin-bottom: 50px;
  height: 550px;
  position: relative;
  margin-top: 190px;
  @media screen and (max-width: 576px) {
    margin-bottom: 0;
    height: 300px;
    display: none;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-bottom: 0;
    height: 400px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-bottom: 0;
    height: 500px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    margin-bottom: 0;
    height: 500px;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    margin-bottom: 80px;
    height: 500px;
    position: relative;
  }
}

.textContainer {
  position: absolute;
  width: 85vw;
  z-index: 10;
  top: calc(50%);
  left: 50%;
  transform: translate(-50%);
  max-width: 1400px;
  @media screen and (max-width: 576px) {
    width: 90vw;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 90vw;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 90vw;
    top: 25%;
    left: 50%;
    transform: translate(-50%);
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 90vw;
    top: 45%;
    left: 50%;
    transform: translate(-50%);
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 85vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    width: 85vw;
  }
}

.img2 {
  position: absolute;
  right: 10px;
  top: 15px;
  @media screen and (max-width: 576px) {
    right: 5px;
    top: 5px;
    width: 72px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    right: 8px;
    top: 10px;
    width: 85px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    right: 10px;
    top: 10px;
    width: 120px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    right: 10px;
    top: 15px;
    width: 140px;
  }
}

.img3 {
  position: absolute;
  left: -30px;
  top: -60px;
  @media screen and (max-width: 576px) {
    left: -10px;
    top: -30px;
    width: 110px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    left: -20px;
    top: -50px;
    width: 140px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    left: -25px;
    top: -60px;
    width: 185px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 230px;
    left: -30px;
    top: -60px;
  }
}

.titleContainer {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #000000 36.46%,
    rgba(36, 36, 36, 0) 100%
  );
}

.title {
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 90px !important;
  line-height: 135px !important;
  text-align: center !important;
  color: #ffffff !important;
  font-family: "Poppins800" !important;
  text-shadow: 0px 0px 4px #ffffff !important;
  @media screen and (max-width: 360px) {
    font-size: 6vw !important;
    line-height: 90px !important;
  }
  @media (min-width: 360px) and (max-width: 576px) {
    font-size: 6vw !important;
    line-height: 90px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 35px !important;
    line-height: 65px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 55px !important;
    line-height: 85px !important;
  }
  @media screen and (min-width: 991px) and (max-width: 1199px) {
    font-size: 65px !important;
    line-height: 85px !important;
  }
  @media screen and (min-width: 1199px) and (max-width: 1400px) {
    font-size: 70px !important;
    line-height: 95px !important;
  }
  @media screen and (min-width: 1400px) and (max-width: 1600px) {
    font-size: 80px !important;
    line-height: 110px !important;
  }
}

.expertiseContainer {
  box-sizing: border-box;
  border: 0.5px solid #f2e024;
  border-radius: 40px;
  padding: 5px 15px;
  width: 399px;
  height: 59px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 576px) {
    width: 180px;
    height: 40px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 180px;
    padding: 5px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 240px;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    width: 300px;
  }
  @media (min-width: 1199px) and (max-width: 1400px) {
    width: 350px;
  }
}
.expertiseContainer:hover,
.expertiseContainer:active,
.expertiseContainer:focus {
  background-color: #f2e024;
  //color: #03a197 !important;
  color: transparent !important;
  -webkit-text-stroke: 1.2px #03a197;
}
.borderBottom {
  border-bottom: 3px solid #f2e024 !important;
}

.borderTop {
  border-top: 3px solid #f2e024 !important;
}
.expertiseTitle {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 30px !important;
  line-height: 45px !important;
  text-align: center !important;
  @media screen and (max-width: 576px) {
    font-size: 12px !important;
    line-height: 40px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 14px !important;
    line-height: 45px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 16px !important;
    line-height: 40px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 22px !important;
    line-height: 40px !important;
  }
  @media (min-width: 1199px) and (max-width: 1400px) {
    font-size: 25px !important;
    line-height: 45px !important;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    font-size: 25px !important;
    line-height: 45px !important;
  }
}

.circle {
  position: relative;
  margin: 0 auto;
  width: 263px;
  height: 263px;
  margin-top: 100px;
  @media screen and (max-width: 576px) {
    width: 110px;
    height: 110px;
    margin-top: 130px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 130px;
    height: 130px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 180px;
    height: 180px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 230px;
    height: 230px;
  }
}

.circle::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #f2e126;
  mix-blend-mode: color-dodge;
  opacity: 0.9;

  border-radius: 100%;
  width: 263px;
  height: 263px;
  @media screen and (max-width: 576px) {
    width: 110px;
    height: 110px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 130px;
    height: 130px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 180px;
    height: 180px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 230px;
    height: 230px;
  }
}

.img4 {
  @media screen and (max-width: 576px) {
    width: 50px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100px;
    height: auto;
  }
}

// here is mobile content

.mobileContainer {
  margin-bottom: 50px;
  height: 550px;
  position: relative;
  display: none;
  @media screen and (max-width: 576px) {
    margin-bottom: 0;
    height: 300px;
    display: unset;
  }
}

.mainContent {
  margin-top: 70px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
