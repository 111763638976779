.errorText {
    color: #d32f2f;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.66 !important;
    letter-spacing: 0.03333em !important;
    text-align: left !important;
    margin-top: 3px !important;
    margin-right: 14px !important;
    margin-bottom: 0 !important;
    margin-left: 14px !important;
  }
  
  .btnContainer {
    justify-content: flex-end !important;
  }
  
  .article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
  }
  .btnContainer2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .article-image {
    width: 50%;
    border-radius: 20px;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 80%;
    }
  }
  
  .article-text {
    text-align: start !important;
  }
  
  .article-categoryContainer {
    text-align: start !important;
    margin-bottom: 12px !important;
  }
  
  .article-category {
    font-family: "Poppins600" !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 36px !important;
    text-align: center !important;
    color: #242424 !important;
    padding: 4px !important;
    background: #dbdbdb !important;
    border-radius: 40px !important;
    @media screen and (max-width: 576px) {
      font-size: 18px !important;
      line-height: 25px !important;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 20px !important;
      line-height: 28px !important;
    }
  }
  