.about {
  background-image: url("../../../../public/assets/images/homePage/bg1.png");
  background-size: cover;
  min-height: 100vh;
  overflow: auto;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: right;
  @media screen and (max-width: 576px) {
    min-height: max-content;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-height: max-content;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    min-height: max-content;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    min-height: max-content;
  }
}

.tg1 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 72px !important;
  text-align: center !important;
  color: #343432 !important;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 320px) {
    font-size: 18px !important;
    line-height: 25px !important;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 22px !important;
    line-height: 25px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 35px !important;
    line-height: 65px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 38px !important;
    line-height: 65px !important;
  }
}

.tg2 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 35px !important;
  line-height: 52px !important;
  text-align: center !important;
  color: #1e1e1e !important;
  @media screen and (max-width: 320px) {
    font-size: 15px !important;
    line-height: 15px !important;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 25px !important;
    line-height: 40px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 28px !important;
    line-height: 52px !important;
  }
}

.tg3 {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #5a5a5a !important;
  @media screen and (max-width: 576px) {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px !important;
    line-height: 25px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 18px !important;
    line-height: 30px !important;
  }
}

.textContainer {
  max-width: 70%;
  margin: 0 auto;
  margin-top: 90px;

  @media screen and (max-width: 320px) {
    margin-top: 40px;
    max-width: 80%;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    margin-top: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 60px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: 70px;
  }
}

.img2 {
  width: 224px;
  height: auto;
  @media screen and (max-width: 320px) {
    width: 60px;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 60px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 140px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 160px;
    height: auto;
  }
}

.img1 {
  width: 200px;
  height: 146px;
  @media screen and (max-width: 320px) {
    width: 50px;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 50px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 130px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 150px;
    height: auto;
  }
}

.img3 {
  width: 696.82px;
  height: auto;
  @media screen and (max-width: 320px) {
    width: 80%;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 70%;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 450px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 500px;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 696.82px;
    height: auto;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 696.82px;
    height: auto;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    width: 696.82px;
    height: auto;
  }
}
