.fullscreenvideo {
  min-height: max-content;//100vh
  width: 100%;
  overflow: auto;
  display: flex;
  video {
    width: 100%;
    height: auto;//100vh
    max-height: 100vh;
    object-fit: fill;//cover
  }
}
