#mainContent {
  position: absolute;
  right: 0;
  min-height: 100vh;
  background-color: rgb(0 0 0 / 3%);
  left: unset;
  @media screen and (max-width: 599px) {
    left: 0;
  }
}

#logoutBtn:hover,
:active,
:focus {
  color: #f2e024;
}

.adminMenuItem {
  color: grey;
  text-decoration: unset;
}
