.card {
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 24px !important;
  gap: 24px !important;
  background: rgba(255, 255, 255, 0.5) !important;
  border: 1px solid #ffffff !important;
  border-radius: 32px !important;
  @media screen and (max-width: 576px) {
    flex-direction: column-reverse !important;
    padding: 14px !important;
    gap: 0px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    flex-direction: column-reverse !important;
    padding: 18px !important;
    gap: 0px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    padding: 18px !important;
    gap: 10px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    padding: 18px !important;
    gap: 10px !important;
  }
}
.cardContent {
  text-align: start;
  @media screen and (max-width: 576px) {
    padding: 0px !important;
    padding-top: 16px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 0px !important;
    padding-top: 16px !important;
  }
}
.cardMedia {
  width: 570px !important;
  height: 320px !important;
  @media screen and (max-width: 576px) {
    width: 100% !important;
    height: auto !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100% !important;
    height: auto !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 370px !important;
    height: auto !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 490px !important;
    height: auto !important;
  }
}

.title {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-size: 36px !important;
  line-height: 54px !important;
  color: #242424 !important;
  text-shadow: 0px 0px 4px #ffffff !important;
  padding-bottom: 24px;
  @media screen and (max-width: 576px) {
    font-size: 24px !important;
    line-height: 30px !important;
    padding-bottom: 10px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 28px !important;
    line-height: 30px !important;
    padding-bottom: 10px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 24px !important;
    line-height: 28px !important;
    padding-bottom: 10px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 30px !important;
    line-height: 35px !important;
    padding-bottom: 10px;
  }
}

.description {
  font-family: "Poppins400" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #242424 !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px !important;
    line-height: 28px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 20px !important;
    line-height: 34px !important;
  }
}

.button {
  font-family: "Poppins600" !important;
  padding: 10px 30px !important;
  gap: 10px !important;
  background: #000000 !important;
  border-radius: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  text-align: center !important;
  color: #ffffff !important;
  text-transform: none !important;
  margin-top: 24px!important;
  @media screen and (max-width: 576px) {
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 5px 20px !important;
    margin-top: 14px!important;
  }
}
