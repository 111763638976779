.mainContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.container {
  display: flex;
  padding: 30px;
  padding-bottom: unset;
  align-items: flex-end;
  @media screen and (max-width: 576px) {
    flex-direction: column-reverse;
    padding-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    flex-direction: column-reverse;
  }
}

.imgContainer {
  padding-left: 30px;
  position: relative;
  width: 40%;
  display: flex;
  @media screen and (max-width: 576px) {
    width: 100%;
    height: auto;
    padding-left: unset;
    display: unset;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    height: auto;
    padding-left: unset;
    display: unset;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 40%;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 40%;
    height: auto;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    width: 40%;
    height: auto;
  }
}

.img1 {
  width: 100%;
  height: auto;
  @media screen and (max-width: 576px) {
    width: 60%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 60%;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 100%;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 100%;
  }
}

.img2 {
  position: absolute;
  bottom: 2%;
  right: 40%;
  width: 48%;
  height: auto;
  mix-blend-mode: multiply;
  @media screen and (max-width: 576px) {
    position: absolute;
    bottom: 2%;
    right: 48%;
    width: 32%;
    height: auto;
    mix-blend-mode: multiply;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    position: absolute;
    bottom: 2%;
    right: 48%;
    width: 32%;
    height: auto;
    mix-blend-mode: multiply;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    position: absolute;
    bottom: 2%;
    right: 40%;
    width: 48%;
    height: auto;
    mix-blend-mode: multiply;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    position: absolute;
    bottom: 2%;
    right: 40%;
    width: 48%;
    height: auto;
    mix-blend-mode: multiply;
  }
}
.img3 {
  position: absolute;
  top: 0%;
  right: 10%;
  width: 15%;
  height: auto;
  @media screen and (max-width: 576px) {
    position: absolute;
    top: 0%;
    right: 26%;
    width: 11%;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    position: absolute;
    top: 0%;
    right: 26%;
    width: 11%;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    position: absolute;
    top: 0%;
    right: 10%;
    width: 15%;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    position: absolute;
    top: 0%;
    right: 10%;
    width: 15%;
    height: auto;
  }
}

.textContainer {
  width: 60%;
  margin-left: 100px;
  margin-right: 20px;
  align-self: flex-start;
  @media screen and (max-width: 576px) {
    margin-left: unset;
    margin-right: unset;
    width: 100%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-left: unset;
    margin-right: unset;
    width: 100%;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-left: 20px;
    margin-right: 10px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    margin-left: 20px;
    margin-right: 10px;
  }
}

.tg2 {
  font-family: "Poppins600" !important;
  text-align: start;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 40px !important;
  line-height: 60px !important;
  color: #ffffff !important;
  &:hover,
  &:active,
  &:focus {
    color: #ffffffa6 !important;
    text-decoration: underline;
    text-decoration-style: solid;
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;
  }
  @media screen and (max-width: 576px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 25px !important;
    line-height: 35px !important;
    margin-bottom: 20px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 25px !important;
    line-height: 28px !important;
    margin-bottom: 10px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 35px !important;
    line-height: 35px !important;
    margin-bottom: 20px;
  }
}

.icon2 {
  margin-right: 10px;
  font-size: 2em;
  @media screen and (max-width: 576px) {
    font-size: 1.2em;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 1.5em;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 1em;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 1.5em;
  }
}

.tg3 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #c7c7c7 !important;
  text-align: start;
  font-family: "Poppins500" !important;
  @media screen and (max-width: 576px) {
    font-size: 13px !important;
    line-height: 19.5px !important;
    margin-top: 5px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 14px !important;
    line-height: 24px !important;
    margin-top: 10px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 10px !important;
    line-height: 16px !important;
    margin-top: 5px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 14px !important;
    line-height: 24px !important;
    margin-top: 10px !important;
  }
}

.listContainer {
  display: flex;
  align-items: flex-start;
  ul {
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    text-align: start;
    font-family: "Poppins400" !important;
    padding-bottom: 30px;
    @media screen and (max-width: 576px) {
      padding-left: 25px;
      padding-bottom: unset;
    }
    @media (min-width: 767px) and (max-width: 991px) {
      font-size: 8px;
      line-height: 14px;
      margin: 5px 0 !important;
    }
  }
  img {
    width: 80px;
    @media screen and (max-width: 576px) {
      font-size: 50px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 50px;
    }
    @media (min-width: 767px) and (max-width: 991px) {
      width: 60px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: 5px;
  }
}

.backIconContainer {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  position: absolute;
  bottom: 15px;
  right: 35px;
  @media screen and (max-width: 576px) {
    bottom: 15px;
    right: 15px;
  }
}

.backIcon {
  width: 40px;
  height: 35px;
  display: unset;
  @media screen and (max-width: 576px) {
    width: 25px;
    height: 25px;
    display: none;
  }
}

.backIconMobile {
  display: none;
  @media screen and (max-width: 576px) {
    display: unset;
    width: 20px;
    height: 20px;
  }
}

.img4 {
  @media screen and (max-width: 576px) {
    display: none;
  }
}
