.carouselSlide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.carouselImage {
  padding-right: 20px;
  @media screen and (max-width: 576px) {
    width: 100px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 130px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 150px;
    height: auto;
  }
}
