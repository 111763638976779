.imageUpload {
  padding: unset !important;
  display: flex !important;
  align-items: center !important;
}
.imageName {
  margin-left: 10px !important;
}

.fileUpload {
  padding: unset !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.fileName {
  margin-top: 10px !important;
}
