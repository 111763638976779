.partners {
  background-image: url("../../../../public/assets/images/homePage/bg1.png");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  overflow: auto;
  background-size: cover;
  background-position: right;
  @media screen and (max-width: 576px) {
    min-height: max-content;
    padding-top: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    min-height: max-content;
    padding-top: 30px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    min-height: max-content;
    padding-top: 30px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    min-height: max-content;
    padding-top: 30px;
  }
}
.tg1 {
  font-family: "Poppins800" !important;
  font-style: normal !important;
  font-size: 92px !important;
  line-height: 111px !important;
  text-align: center !important;
  color: rgba(30, 30, 30, 1) !important;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 576px) {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 40px !important;
    line-height: 48px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 52px !important;
    line-height: 60px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 62px !important;
    line-height: 80px !important;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 72px !important;
    line-height: 90px !important;
  }
}
.tg2 {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-size: 115px !important;
  line-height: 139px !important;
  text-align: center !important;
  color: rgba(30, 30, 30, 1) !important;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 576px) {
    font-size: 25px !important;
    line-height: 35px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 50px !important;
    line-height: 60px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 62px !important;
    line-height: 70px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 82px !important;
    line-height: 90px !important;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 92px !important;
    line-height: 100px !important;
  }
}

.partnersContainer {
  margin-bottom: 50px;
  @media screen and (max-width: 576px) {
    margin-top: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 50px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: 50px;
  }
}
