.elephant {
  background-image: url("../../../../public/assets/images/homePage/bg3.png");
  min-height: max-content;
  display: flex;
  justify-content: center;
  overflow: auto;
  background-size: cover;

  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    flex-direction: column;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    flex-direction: column;
  }
  img {
    width: 40%;
    height: auto;
    align-self: center;
    @media screen and (max-width: 420px) {
      width: 90%;
      height: auto;
    }
    @media (min-width: 420px) and (max-width: 576px) {
      width: 90%;
      height: auto;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 90%;
      height: auto;
    }
  }
}

.tgContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  align-items: center;
  gap: 80px;
  padding: 20px 0;
  @media screen and (max-width: 320px) {
    margin-top: 30px;
    gap: 30px;
    margin-bottom: 20px;
    padding: unset;
  }
  @media (min-width: 320px) and (max-width: 420px) {
    margin-top: 30px;
    gap: 40px;
    margin-bottom: 20px;
    padding: unset;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    margin-top: 40px;
    gap: 60px;
    margin-bottom: 20px;
    padding: unset;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-top: 40px;
    gap: 100px;
    margin-bottom: 20px;
    padding: unset;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-top: 40px;
    gap: 40px;
    margin-bottom: 20px;
    padding: unset;
  }
}

.tg1 {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 6vw !important;
  line-height: 40px !important;
  color: #242424 !important;
  margin-left: 25px !important;
  @media screen and (max-width: 320px) {
    font-size: 10vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 320px) and (max-width: 420px) {
    font-size: 10vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    font-size: 10vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 10vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 5vw !important;
    line-height: 40px !important;
  }
}

.tg2 {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 11vw !important;
  line-height: 50px !important;
  color: #242424 !important;
  @media screen and (max-width: 420px) {
    font-size: 20vw !important;
    line-height: 50px !important;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    font-size: 20vw !important;
    line-height: 50px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20vw !important;
    line-height: 50px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 10vw !important;
    line-height: 50px !important;
  }
}

.tg3 {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 5vw !important;
  line-height: 40px !important;
  color: #242424 !important;
  @media screen and (max-width: 320px) {
    font-size: 9vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 320px) and (max-width: 420px) {
    font-size: 9vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    font-size: 9vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 9vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 4vw !important;
    line-height: 40px !important;
  }
}

.tg4 {
  font-family: "Poppins900" !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 14vw !important;
  line-height: 60px !important;
  color: #242424 !important;
  @media screen and (max-width: 420px) {
    font-size: 24vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    font-size: 24vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 24vw !important;
    line-height: 40px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 13vw !important;
    line-height: 60px !important;
  }
}

.tgIconCotainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.icon1 {
  width: 2vw !important;
  height: 2vw !important;
  margin-bottom: 3vw;
  margin-left: -3px;
  @media screen and (max-width: 320px) {
    width: 15px !important;
    height: 15px !important;
    margin-bottom: 5.5vw;
    margin-left: -3px;
  }
  @media (min-width: 320px) and (max-width: 420px) {
    width: 18px !important;
    height: 18px !important;
    margin-bottom: 5.5vw;
    margin-left: -3px;
  }

  @media (min-width: 420px) and (max-width: 576px) {
    width: 18px !important;
    height: 18px !important;
    margin-bottom: 5.5vw;
    margin-left: -3px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    width: 25px !important;
    height: 25px !important;
    margin-bottom: 5.5vw;
    margin-left: -3px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 20px !important;
    height: 20px !important;
    margin-bottom: 3vw;
    margin-left: -3px;
  }
}

.icon2 {
  margin-left: 1vw;
  width: 4vw !important;
  height: 4vw !important;
  margin-top: 4vw;
  @media screen and (max-width: 320px) {
    width: 30px !important;
    height: 30px !important;
    margin-top: 25px;
    margin-left: -20px;
  }
  @media (min-width: 320px) and (max-width: 420px) {
    width: 35px !important;
    height: 35px !important;
    margin-top: 25px;
    margin-left: -20px;
  }
  @media (min-width: 420px) and (max-width: 576px) {
    width: 35px !important;
    height: 35px !important;
    margin-top: 25px;
    margin-left: -20px;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    margin-left: -20px;
    width: 45px !important;
    height: 45px !important;
    margin-top: 45px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    margin-left: 1vw;
    width: 4vw !important;
    height: 4vw !important;
    margin-top: 1.8vw;
  }
}
