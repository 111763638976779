.container {
  background-image: url("../../../../public/assets/images/homePage/bg1.png");
  background-size: cover;
  min-height: max-content;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  flex-direction: column;
  background-position: top;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }
}

.tg1 {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 64px !important;
  line-height: 96px !important;
  text-align: center !important;
  color: #242424 !important;
  @media screen and (max-width: 576px) {
    font-size: 6vw !important;
    line-height: 26px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px !important;
    line-height: 36px !important;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 40px !important;
    line-height: 56px !important;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 50px !important;
    line-height: 56px !important;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    font-size: 60px !important;
    line-height: 66px !important;
  }
  @media (min-width: 1400px) and (max-width: 1600px) {
    font-size: 64px !important;
    line-height: 76px !important;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
}
.imgContainer1 {
  align-self: flex-start;
  display: flex;
  align-items: center;
  position: relative;
}
.imgContainer2 {
  align-self: flex-end;
  display: flex;
  align-items: center;
  position: relative;
}
.tg2 {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  text-align: center !important;
  color: #5a5a5a !important;
  padding-right: 150px;
  padding-left: 150px;
  @media screen and (max-width: 320px) {
    font-size: 14px !important;
    line-height: 24px !important;
    padding-right: 20px;
    padding-left: 20px;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    font-size: 14px !important;
    line-height: 24px !important;
    padding-right: 30px;
    padding-left: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 18px !important;
    line-height: 30px !important;
    padding-right: 80px;
    padding-left: 80px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 20px !important;
    line-height: 30px !important;
    padding-right: 100px;
    padding-left: 100px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    font-size: 20px !important;
    line-height: 30px !important;
    padding-right: 120px;
    padding-left: 120px;
  }
}

.img2 {
  width: 224px;
  height: 169px;
  @media screen and (max-width: 320px) {
    width: 40px;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 60px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 140px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 160px;
    height: auto;
  }
}

.img1 {
  width: 200px;
  height: 146px;
  @media screen and (max-width: 320px) {
    width: 40px;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 60px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 130px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 150px;
    height: auto;
  }
}

.img3,
.img4 {
  width: 220px;
  height: auto;
  @media screen and (max-width: 320px) {
    width: 60px;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 80px;
    height: auto;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 160px;
    height: auto;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 180px;
    height: auto;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 180px;
    height: auto;
  }
}

.img5 {
  width: 67px;
  height: auto;
  left: 210px;
  top: 50px;
  position: absolute;

  @media screen and (max-width: 320px) {
    width: 27px;
    height: auto;
    left: 60px;
    top: 4px;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 35px;
    height: auto;
    left: 80px;
    top: 12px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 47px;
    height: auto;
    left: 150px;
    top: 35px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 57px;
    height: auto;
    left: 170px;
    top: 40px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 57px;
    height: auto;
    left: 170px;
    top: 40px;
  }
}
.img6 {
  width: 67px;
  height: auto;
  right: 210px;
  top: 5px;
  position: absolute;

  @media screen and (max-width: 320px) {
    width: 27px;
    height: auto;
    right: 60px;
    top: 0px;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    width: 35px;
    height: auto;
    right: 80px;
    top: 0px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 47px;
    height: auto;
    right: 150px;
    top: 5px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 57px;
    height: auto;
    right: 170px;
    top: 5px;
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    width: 57px;
    height: auto;
    right: 170px;
    top: 5px;
  }
}
