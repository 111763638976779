.google-map {
  width: 100%;
  height: 250px;
  div {
    border-radius: 20px !important;
  }
  @media screen and (max-width: 320px) {
    height: 200px;
  }
  @media (min-width: 320px) and (max-width: 576px) {
    height: 200px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    height: 200px;
  }
}

.pin {
  display: flex;
  width: max-content;
}

.pin-icon {
  width: 25px;
  height: 25px;
}

.pin-text {
  font-size: 10px;
  color: black;
  background-color: white;
  padding: 2px 3px;
  border-radius: 10px;
  opacity: 0.7;
  margin: 0 !important;
  width: 100px;
}

@media screen and (min-width: 799px) {
  .pin-icon {
    width: 20px;
    height: 20px;
  }
}
