.link {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 36px !important;
  text-align: center !important;
  text-decoration-line: underline !important;
  color: rgba(36, 36, 36, 0.6) !important;
  @media screen and (max-width: 576px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.title {
  font-family: "Poppins700" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 48px !important;
  color: #000000 !important;
  text-align: start;
  @media screen and (max-width: 576px) {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 28px !important;
    line-height: 38px !important;
  }
}

.category {
  font-family: "Poppins600" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  text-align: center !important;
  color: #242424 !important;
  padding: 4px !important;
  background: #dbdbdb !important;
  border-radius: 40px !important;
  @media screen and (max-width: 576px) {
    font-size: 18px !important;
    line-height: 25px !important;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

.categoryContainer {
  text-align: start !important;
  margin-bottom: 12px !important;
}

.cardContainer {
  border-radius: 20px 20px 0 0 !important;
  padding: 16px !important;
}

.card {
  border-radius: 20px !important;
}
