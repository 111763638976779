.prevArrow {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 2.5rem;
  border: none;
  border-radius: 50%;
  padding: 5px;
  z-index: 2;
  &:hover {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
    bottom: 10px!important;
    padding-bottom: 0;
    top: unset;
    transform: translateY(0);
    &:hover {
      font-size: 2rem;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 1.7rem;
    bottom: 10px!important;
    padding-bottom: 0;
    top: unset;
    transform: translateY(0);
    &:hover {
      font-size: 2.2rem;
    }
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 2rem;

    &:hover {
      font-size: 2.5rem;
    }
  }
}

.nextArrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 2.5rem;
  border: none;
  border-radius: 50%;
  padding: 5px;
  z-index: 2;
  &:hover {
    font-size: 3.5rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
    bottom: 10px!important;
    padding-bottom: 0;
    transform: translateY(0);
    top: unset;
    &:hover {
      font-size: 2rem;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 1.7rem;
    bottom: 10px!important;
    padding-bottom: 0;
    transform: translateY(0);
    top: unset;
    &:hover {
      font-size: 2.2rem;
    }
  }
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 2rem;

    &:hover {
      font-size: 2.5rem;
    }
  }
}
