#customCard {
  .cardContents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start !important;
  }
  .text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
